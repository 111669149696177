<template>
  <div>
    <c-table
      ref="table"
      title="설비정비 목록"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :merge="grid.merge"
      rowKey="minEquipmentMaintenanceId"
      @linkClick="linkClick"
    >
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['maintenanceStatusCd']">
          <q-chip outline square
            :color="setTagColor(props.row.maintenanceStatusCd)"
            :class="props.row.maintenanceStatusCd ==='MCSC000020' ? 'blinking' : null"
            text-color="white"
            >
            <q-avatar v-if="props.row.maintenanceStatusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
            {{setTagName(props.row.maintenanceStatusCd)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "equipment-inspection",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          equipmentTypeCd: '',
          grade: '',
          inspectionCycleCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: "plantNm" }
          ,{ index: 1, colName: "equipmentCdName" }],
        columns: [
          {
            name: "plantNm",
            field: "plantNm",
            style: 'width:100px',
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            style: 'width:200px',
            label: "설비명(코드)",
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "maintenanceStatusCd",
            field: "maintenanceStatusCd",
            label: "진행상태",
            style: 'width:100px',
            align: "center",
            sortable: true,
            type: 'custom',
          },
          {
            name: "maintenanceDueDate",
            field: "maintenanceDueDate",
            label: "정비예정일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceDueUserName",
            field: "maintenanceDueUserName",
            style: 'width:100px',
            label: "정비예정자",
            align: "center",
            sortable: true,
          },
          {
            name: "memDeptEntrantUserName",
            field: "memDeptEntrantUserName",
            style: 'width:110px',
            label: "정비부서입회자",
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceUserName",
            field: "maintenanceUserName",
            style: 'width:100px',
            label: "실제정비자",
            align: "center",
            sortable: true,
          },
          {
            name: "maintenanceDate",
            field: "maintenanceDate",
            label: "정비완료일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "inspResultAfterMemName",
            field: "inspResultAfterMemName",
            label: "정비결과",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCheckTypeName",
            field: "equipmentCheckTypeName",
            label: "정비유형",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      colorItems: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      searchParam: {
        plantCd: null,
        equipmentCheckTypeCd: null,
        maintenanceStatusCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,

        equipmentTypeCd: '',
        grade: '',
      },
      period: [ '', ''],
      editable: true,
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.min.maintenance.plan.list.url;
      // code setting
      
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd;
        this.searchParam.equipmentCheckTypeCd = this.popupParam.equipmentCheckTypeCd;
        this.searchParam.grade = this.popupParam.grade;
      }
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      // this.getList();
    },
  },
};
</script>
<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>